import classNames from "classnames"
import Image, { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { Link } from "../../../../components/link/link"
import styles from "./support-box.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface SupportBoxProps {
  image?: string
  fluidImage?: FluidObject
  boxShadowVariant?: "coral" | "green"
  title: string
  subtitle: string
  button: {
    text: string
    url: string
  }
  link?: {
    text: string
    url: string
  }
  className?: string
}

export const SupportBox: FunctionComponent<SupportBoxProps> = ({
  image,
  fluidImage,
  title,
  subtitle,
  button,
  link,
  className,
  boxShadowVariant = "coral"
}) => (
  <div
    className={classNames(styles.supportBox, className)}
    id="supporting-ligonier"
  >
    <div className={classNames(styles.image, styles[boxShadowVariant])}>
      {fluidImage ? (
        <Image fluid={fluidImage} data-src={fluidImage.src} alt="" />
      ) : (
        <img data-src={image} alt="" className="lazyload" />
      )}
    </div>
    <div className={styles.text}>
      <Markdown className={styles.subtitle} content={subtitle} />
      <Markdown className={styles.title} content={title} />
      <div className={styles.buttons}>
        {button.text && (
          <Button size="md" variant="primary" to={button.url}>
            {button.text}
          </Button>
        )}
        {link && (
          <Link className={styles.link} to={link.url}>
            {link.text}
          </Link>
        )}
      </div>
    </div>
  </div>
)
