import React, { FunctionComponent } from "react"
import { Markdown } from "@components/markdown/markdown"
import {
  stripAttributesFromTag,
  wrapElementInTag
} from "@features/posts/posts-helpers"

export interface ToHTMLFormatYoutubeEmbedProps {
  className?: string
  text: string
}

export const ToHTMLFormatYoutubeEmbed: FunctionComponent<
  ToHTMLFormatYoutubeEmbedProps
> = ({ text, className }) => {
  // This strips the width and height attributes that may previously be set on the html string
  // in the contentful markdown editor
  const stripIframeWidthAndHeight = (htmlString: string): string =>
    stripAttributesFromTag({
      htmlString,
      tag: "iframe",
      targetAttributes: "width height"
    })

  // After stripping we need to wrap all iframes in an article element with a 'iframe-container'
  //  data attr. allowing us to apply the global styling that ensures both 100% width AND preservation
  // of aspect ratio for all youtube embeds found in the supplied html text string
  const strippedAndWrappedText = wrapElementInTag({
    htmlString: stripIframeWidthAndHeight(text),
    selector: "iframe",
    wrapper: '<article data="iframe-container"></article>'
  })

  return <Markdown content={strippedAndWrappedText} className={className} />
}
